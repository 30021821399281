<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref } from 'vue';

import { SPARK_VIRTUALISED_SELECT_CONTEXT_KEY } from './share';

const props = defineProps<{
  isHighlighted: boolean;
  isSelected: boolean;
  index: number;
}>();
const el = ref<HTMLElement>();
const context = inject(SPARK_VIRTUALISED_SELECT_CONTEXT_KEY);

onMounted(() => {
  if (el.value) {
    context?.setItemMounted(props.index, el.value);
  }
});

onUnmounted(() => {
  context?.setItemUnmounted(props.index);
});
</script>
<template>
  <div
    ref="el"
    role="option"
    :data-is-selected="isSelected"
    :data-is-highlighted="isHighlighted"
    class="block w-full cursor-pointer whitespace-nowrap px-4 py-1 text-left data-[is-highlighted='true']:bg-gray-100 data-[is-selected='true']:bg-gray-200 data-[is-selected='true']:font-medium"
  >
    <slot></slot>
  </div>
</template>
