export type AnnouncementContent = {
  icon: 'freight' | 'basis' | 'access' | 'rocket';
  heading: string;
  description: string;
  screenshot: string;
  bulletPoints: string[];
  link: string;
  linkText: string;
};

export const content: AnnouncementContent[] = [
  {
    icon: 'freight',
    heading: 'Global Calculator',
    description:
      'Calculate Voyage Details, Charterer Costs and Owner Earnings summaries for customisable global routes and vessel specs',
    screenshot: '/announcement/global-calc-screenshot.png',
    bulletPoints: [
      'Choose different vessel specs and voyages to understand cost component calculations transparently',
      'Calculate implied FOB LNG price with known DES LNG price or vice versa',
      'Emissions costs within the EU framework included, with methane slip, and CO2, CH4 and N2O',
      'Share your inputs with colleagues using the Share link',
    ],
    link: '/freight/global-calculator',
    linkText: 'Launch Global Calculator',
  },
  {
    icon: 'rocket',
    heading: 'Spark Signals',
    description: 'Unlock historical trends with Spark data series',
    screenshot: '/announcement/spark-signals-screenshot.png',
    bulletPoints: [
      'Explore 100,000 historical data points for Cargo arbs, netbacks and routes to which you are subscribed',
      'Export data series easily into Excel',
    ],
    link: '/spark-signals',
    linkText: 'Launch Spark Signals',
  },
];
