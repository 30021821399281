<script lang="ts" setup>
import { ref } from 'vue';

import type {
  DefaultLook,
  SparkButtonProps,
} from '#/components/generic/SparkButton/types';

const copyTextStatus = ref<'' | 'success' | 'fail'>('');

const props = defineProps<{
  content: string;
  color: SparkButtonProps['color'];
  look: DefaultLook | 'string';
}>();

const getIconFromStatus = (status: '' | 'success' | 'fail') => {
  switch (status) {
    case '':
      return 'copy';
    case 'success':
      return 'check';
    case 'fail':
      return 'clear';
    default:
      return '';
  }
};

const getButtonTextFromStatus = (status: '' | 'success' | 'fail') => {
  switch (status) {
    case '':
      return 'Copy';
    case 'success':
      return 'Copied!';
    case 'fail':
      return 'Error copying';
    default:
      return '';
  }
};

const copyText = async () => {
  try {
    await navigator.clipboard.writeText(props.content);
    copyTextStatus.value = 'success';
  } catch (err) {
    console.log(err);
    copyTextStatus.value = 'fail';
    console.error(err);
  } finally {
    setTimeout(() => {
      copyTextStatus.value = '';
    }, 2000);
  }
};
</script>
<template>
  <SparkTrackerButton
    :look="look"
    :color="color"
    :disabled="copyTextStatus !== ''"
    :icon="getIconFromStatus(copyTextStatus)"
    @click="copyText"
    >{{ getButtonTextFromStatus(copyTextStatus) }}</SparkTrackerButton
  >
</template>
