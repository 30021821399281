<script setup lang="ts">
import { DownOutlined } from '@ant-design/icons-vue';
import { PopoverTrigger } from 'radix-vue';
import { inject } from 'vue';

import { SPARK_VIRTUALISED_SELECT_CONTEXT_KEY } from './share';

const context = inject(SPARK_VIRTUALISED_SELECT_CONTEXT_KEY);

function onKeyDown(event: KeyboardEvent) {
  if (event.key === 'ArrowDown') {
    event.preventDefault();
    context?.incrementHighlightIndex();
  } else if (event.key === 'ArrowUp') {
    event.preventDefault();
    context?.decrementHighlightIndex();
  } else if (event.key === 'Enter' || event.key === ' ') {
    if (context?.open.value) {
      event.preventDefault();
      context?.selectHighlightIndex();
    }
  }
}
</script>
<template>
  <PopoverTrigger
    class="popover-trigger flex cursor-pointer select-none items-center justify-between gap-1 rounded-md border border-[#d9d9d9] bg-white font-medium text-[#000000D9] transition-colors hover:border-[--virtualised-select-trigger-hover-border-color] hover:bg-white focus:border-[--virtualised-select-trigger-hover-border-color] focus:outline-none focus:drop-shadow-[--virtualised-select-trigger-glow-color] disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400 disabled:hover:border-[#d9d9d9] data-[size=lg]:h-10 data-[size=md]:h-8 data-[size=sm]:h-6 data-[size=lg]:px-5 data-[size=md]:px-4 data-[size=sm]:px-2 data-[size=lg]:text-lg data-[size=md]:text-sm data-[size=xs]:text-sm data-[state='open']:text-gray-500"
    @keydown="onKeyDown"
  >
    <slot />
    <DownOutlined class="popover-trigger-caret" />
  </PopoverTrigger>
</template>

<style scoped>
.popover-trigger[data-size='sm'] .popover-trigger-caret {
  @apply text-[12px] leading-none;
}
.popover-trigger[data-size='md'] .popover-trigger-caret {
  @apply text-[13px] leading-none;
}
.popover-trigger[data-size='lg'] .popover-trigger-caret {
  @apply text-[14px] leading-none;
}
</style>
