export type StaticResourceEnum =
  | 'lng-freight/methodology/historical-data.md'
  | 'lng-freight/methodology/signals.md'
  | 'lng-freight/methodology/settlement-tracker.md'
  | 'lng-freight/methodology/spread.md'
  | 'lng-freight/methodology/roundtrip-calc.md'
  | 'lng-freight/methodology/offset-calc.md'
  | 'lng-freight/methodology/dashboard.md'
  | 'lng-freight/methodology/routes.md'
  | 'lng-freight/methodology/spark174-info.md'
  | 'lng-freight/methodology/spark174-ratio-details.md'
  | 'lng-freight/methodology/price-type.md'
  | 'lng-freight/methodology/floating-storage.md'
  | 'lng-freight/methodology/global-calculator.md'

  // FFA
  | 'lng-freight/methodology/ffa.md'
  | 'lng-freight/methodology/ffa-price-type.md'

  // Basis
  | 'lng-basis/methodology/settlement-tracker.md'
  | 'lng-basis/methodology/physical.md'
  | 'lng-basis/methodology/historical-data.md'
  | 'lng-basis/methodology/netbacks.md'
  | 'lng-basis/methodology/netforwards.md'
  | 'lng-basis/methodology/spark-close-sandbox.md'
  | 'lng-basis/methodology/spark-close-restrictions.md'
  | 'lng-basis/methodology/spark-close-credit-compliance.md'
  | 'lng-basis/methodology/spark-close-restriction-phase-1.md'
  | 'lng-basis/methodology/spark-close-restriction-phase-2.md'
  | 'lng-basis/methodology/spark-close-restriction-phase-3.md'

  // Basis FFA
  | 'lng-basis/methodology/financial.md'

  // Access
  | 'spark-access/lng-regas-costing-methodology.md'
  | 'spark-access/sparkr-methodology.md'
  | 'spark-access/sparkr-changelog.md'
  | 'spark-access/sparkr-regas-methodology.md'
  | 'spark-access/sparkr-des-hub-methodology.md'
  | 'spark-access/sparkr-fob-hub-methodology.md'

  // Integrations
  | 'data-integration/integration-powerbi.md'
  | 'data-integration/integration-excel-addin.md'
  | 'data-integration/integration-excel-addin-v2.md'
  | 'data-integration/integration-spark-api.md'
  | 'data-integration/integration-spark-api-v2.md'
  | 'data-integration/integration-spark-webhook.md'
  | 'data-integration/integration-enverus.md';

// TODO: uncomment this when ready
// SparkHub
// | 'sparkhub/methodology.md';

export const StaticResourceEnum: Record<string, StaticResourceEnum> = {
  // Freight
  FreightHistoricalDataMethodology:
    'lng-freight/methodology/historical-data.md',
  FreightPriceSignalsMethodology: 'lng-freight/methodology/signals.md',
  FreightSettlementTrackerMethodology:
    'lng-freight/methodology/settlement-tracker.md',
  FreightSpreadMethodology: 'lng-freight/methodology/spread.md',
  FreightRTCalcMethodology: 'lng-freight/methodology/roundtrip-calc.md',
  FreightOffsetCalcMethodology: 'lng-freight/methodology/offset-calc.md',
  FreightDashboardMethodology: 'lng-freight/methodology/dashboard.md',
  FreightRoutesMethodology: 'lng-freight/methodology/routes.md',
  FreightSpark174Info: 'lng-freight/methodology/spark174-info.md',
  FreightSpark174RatioDetails:
    'lng-freight/methodology/spark174-ratio-details.md',
  FreightPriceTypeMethodology: 'lng-freight/methodology/price-type.md',
  FreightFloatingStorage: 'lng-freight/methodology/floating-storage.md',
  FreightGlobalCalculatorMethodology:
    'lng-freight/methodology/global-calculator.md',

  // FFA
  FFAMethodology: 'lng-freight/methodology/ffa.md',
  FFAPriceTypeMethodology: 'lng-freight/methodology/ffa-price-type.md',

  // Basis
  BasisSettlementTrackerMethodology:
    'lng-basis/methodology/settlement-tracker.md',
  BasisMethodology: 'lng-basis/methodology/physical.md',
  BasisHistoricalDataMethodology: 'lng-basis/methodology/historical-data.md',
  BasisNetbackMethodology: 'lng-basis/methodology/netbacks.md',
  BasisNetforwardsMethodology: 'lng-basis/methodology/netforwards.md',
  BasisSparkCloseSandboxMethodology:
    'lng-basis/methodology/spark-close-sandbox.md',
  BasisSparkCloseRestrictionsMethodology:
    'lng-basis/methodology/spark-close-restrictions.md',
  BasisSparkCloseRestrictionPhase1Methodology:
    'lng-basis/methodology/spark-close-restriction-phase-1.md',
  BasisSparkCloseRestrictionPhase2Methodology:
    'lng-basis/methodology/spark-close-restriction-phase-2.md',
  BasisSparkCloseRestrictionPhase3Methodology:
    'lng-basis/methodology/spark-close-restriction-phase-3.md',
  BasisSparkCloseCreditComplianceMethodology:
    'lng-basis/methodology/spark-close-credit-compliance.md',

  // BasisFFA
  BasisFFAMethodology: 'lng-basis/methodology/financial.md',

  // Access
  AccessMethodology: 'spark-access/lng-regas-costing-methodology.md',
  AccessSparkRMethodology: 'spark-access/sparkr-methodology.md',
  AccessChangelog: 'spark-access/sparkr-changelog.md',
  AccessSparkRRegasMethodology: 'spark-access/sparkr-regas-methodology.md',
  AccessSparkRDesHubMethodology: 'spark-access/sparkr-des-hub-methodology.md',
  AccessSparkRFobHubMethodology: 'spark-access/sparkr-fob-hub-methodology.md',

  // Integrations
  IntegrationPowerBiMarkdown: 'data-integration/integration-powerbi.md',
  IntegrationExcelMarkdown: 'data-integration/integration-excel-addin.md',
  IntegrationExcelMarkdownV2: 'data-integration/integration-excel-addin-v2.md',
  IntegrationSparkApiMarkdown: 'data-integration/integration-spark-api-v2.md',
  IntegrationSparkWebhooksMarkdown:
    'data-integration/integration-spark-webhook.md',
  IntegrationEnverusMarkdown: 'data-integration/integration-enverus.md',

  // TODO: uncomment this when ready
  // SparkHub
  // SparkHubMethodology: 'sparkhub/methodology.md',
};
