import ApiBase from '@/core/services/ApiBase';
import type { GlobalCalcCalculationDTO, paths } from '@/types';

export default class GlobalCalcService extends ApiBase {
  async getReferenceData() {
    const path = 'v1.0/global-calculator/reference-data';

    type Response =
      paths['/v1.0/global-calculator/reference-data']['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    return response?.data;
  }

  async getCalculationByUuid(uuid: string) {
    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);
    return response?.data;
  }

  async saveCalculation(name: string, data: any) {
    const path = `v1.0/global-calculator/calculations`;

    type Response =
      paths[`/v1.0/global-calculator/calculations`]['post']['responses']['201']['content']['application/json'];

    const response = await this.$http.post<Response>(path, {
      name,
      parameters: data,
    });
    return response?.data;
  }

  async getCalculations() {
    const path = `v1.0/global-calculator/calculations`;

    type Response =
      paths[`/v1.0/global-calculator/calculations`]['get']['responses']['200']['content']['application/json'];

    const response = await this.$http.get<Response>(path);

    return response?.data?.data as unknown as GlobalCalcCalculationDTO[];
  }

  async updateCalculation(
    uuid: string,
    { name, parameters }: { name?: string; parameters?: Record<string, any> },
  ) {
    const updatedFields = {} as {
      name?: string;
      parameters?: Record<string, any>;
    };
    if (name) {
      updatedFields['name'] = name;
    }
    if (parameters) {
      updatedFields['parameters'] = parameters;
    }

    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['patch']['responses']['200']['content']['application/json'];
    const response = await this.$http.patch<Response>(path, updatedFields);
    return response?.data;
  }

  async deleteCalculation(uuid: string) {
    const path = `v1.0/global-calculator/calculations/${uuid}`;

    type Response =
      paths[`/v1.0/global-calculator/calculations/{uuid}`]['delete']['responses']['200']['content']['application/json'];
    const response = await this.$http.delete<Response>(path);
    return response?.data;
  }
}
