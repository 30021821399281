<script setup lang="ts">
/**
 * See ./announcement.md on how to update announcements
 */

import { useElementSize, useElementVisibility, watchOnce } from '@vueuse/core';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  VisuallyHidden,
} from 'radix-vue';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { getItem, setItem } from '@/core/misc/UserLocalStorage';
import { useAppStore } from '@/core/stores/app';
import { useAuthStore } from '@/core/stores/auth';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import { AnalyticsEvent } from '@/types';
import ImageLoader from '#/components/generic/ImageLoader.vue';

import { type AnnouncementContent, content } from './announcement-content';
import ScrollMoreIndicator from './ScrollMoreIndicator.vue';

const ANNOUNCEMENT_ID = '2025-01.3';
const STORAGE_ID_KEY = 'announcement-popup';
const DELAY = 5000;
const ROUTES_TO_SHOW = [
  '/dashboard',
  '/freight',
  '/cargo',
  '/access',
  '/spark-signals',
];

const authStore = useAuthStore();
const route = useRoute();
const appStore = useAppStore();

const isModalVisible = ref(false);
const mainContentContainer = ref<HTMLDivElement>();
const mainContent = ref<HTMLElement>();
const hasScrolledToBottom = ref(false);
const bottomMostElement = ref<HTMLDivElement>();
const allImagesLoaded = ref(false);

const { height: containerHeight } = useElementSize(mainContentContainer);
const { height: contentHeight } = useElementSize(mainContent);

const imageUrls = new Set<string>();

let timeout: ReturnType<typeof setTimeout> | undefined;

appStore.listenEvent('announcement', () => {
  isModalVisible.value = true;
});

const targetIsVisible = useElementVisibility(bottomMostElement);

const showScrollIndicator = computed(() => {
  if (!mainContentContainer.value) {
    return false;
  }

  if (!allImagesLoaded.value) {
    return false;
  }

  if (hasScrolledToBottom.value) {
    return false;
  }

  return containerHeight.value < contentHeight.value;
});

function getImageUrl(path: string) {
  const url = new URL(path, import.meta.url).href;
  return url;
}

const iconColor: Record<AnnouncementContent['icon'], string> = {
  freight: 'text-green-500',
  basis: 'text-basis-500',
  access: 'text-access-500',
  rocket: 'text-gray-800',
};

async function acknowledge(closeModal: boolean) {
  if (closeModal) {
    isModalVisible.value = false;
  }
  setItem(STORAGE_ID_KEY, ANNOUNCEMENT_ID);
  analyticsTrack(AnalyticsEvent.AnnouncementAcknowledged, {
    announcementId: ANNOUNCEMENT_ID,
  });
}

function areConditionsMet() {
  const routeIncludes = ROUTES_TO_SHOW.some((r) => route.path.includes(r));
  const loggedIn = authStore.loggedIn;
  const isNotInLocalStorage = getItem(STORAGE_ID_KEY) !== ANNOUNCEMENT_ID;
  const hasDoneOnboarding = authStore.userAccount?.hasVisitedOnboardingPage;
  const allConditions = [
    routeIncludes,
    loggedIn,
    isNotInLocalStorage,
    hasDoneOnboarding,
  ];
  return allConditions.every((condition) => condition === true);
}

function tryShowAnnouncement() {
  const toShow = areConditionsMet();
  if (!toShow) {
    return;
  }
  isModalVisible.value = true;
}

function showAnnouncementAfterDelay() {
  if (timeout !== undefined) {
    return;
  }

  timeout = setTimeout(() => {
    tryShowAnnouncement();
    clearTimeout(timeout);
    timeout = undefined;
  }, DELAY);
}

function onScrollAtIndicator(delta: number) {
  if (mainContentContainer.value) {
    mainContentContainer.value.scrollTop += delta;
  }
}

function onImageLoaded(src: string) {
  imageUrls.add(src);

  allImagesLoaded.value = content.every((item) =>
    imageUrls.has(getImageUrl(item.screenshot)),
  );
}

watch([() => authStore.loggedIn, () => route.path], () => {
  showAnnouncementAfterDelay();
});

watchOnce(showScrollIndicator, (showScrollIndicatorValue) => {
  if (showScrollIndicatorValue) {
    watchOnce(targetIsVisible, (targetIsVisibleValue) => {
      if (targetIsVisibleValue) {
        hasScrolledToBottom.value = true;
      }
    });
  }
});
</script>

<template>
  <DialogRoot v-model:open="isModalVisible">
    <DialogPortal to="#announcement">
      <Transition name="fade">
        <DialogOverlay class="fixed inset-0 bg-black/50" />
      </Transition>

      <Transition name="grow">
        <div
          class="fixed inset-0 flex items-center justify-center overflow-auto"
          v-if="isModalVisible"
        >
          <DialogContent @interact-outside="acknowledge(true)">
            <div
              class="relative max-h-[calc(100svh-4rem)] w-[430px] bg-transparent bg-white fhd:h-[610px] fhd:max-h-[610px] fhd:w-[800px]"
            >
              <VisuallyHidden>
                <DialogTitle>Announcement</DialogTitle>
                <DialogDescription>New Feature Launch</DialogDescription>
              </VisuallyHidden>

              <div
                class="relative grid grid-rows-[auto_1fr] fhd:h-full fhd:max-h-full fhd:grid-cols-[316px_1fr] fhd:grid-rows-1"
              >
                <!-- left-side / header -->
                <div
                  class="flex items-center justify-center bg-green-500 p-6 fhd:p-8"
                >
                  <div class="space-y-4 fhd:space-y-8">
                    <div class="flex items-center justify-center gap-1">
                      <SparkIcon icon="logo" class="h-10 w-10 text-white" />
                      <span
                        class="mt-1 font-display text-4xl font-semibold text-white"
                        >Spark</span
                      >
                    </div>
                    <div
                      class="text-center text-xl font-medium tracking-[0.4rem]"
                    >
                      UPDATES
                    </div>
                    <div class="hidden fhd:flex fhd:justify-center">
                      <div class="h-1 w-8 bg-white"></div>
                    </div>
                    <div class="space-y-2 text-center font-medium text-white">
                      <div class="text-lg text-green-100">
                        New Features Launch
                      </div>
                      <div class="flex flex-wrap items-center gap-2 fhd:block">
                        <div class="text-2xl fhd:text-3xl">
                          Global Calculator
                        </div>
                        <div class="text-xl text-green-100">&</div>
                        <div class="text-2xl fhd:text-3xl">Spark Signals</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- main content -->
                <div
                  ref="mainContentContainer"
                  class="max-h-[500px] overflow-auto fhd:max-h-none"
                >
                  <div class="divide-y-1 divide-gray-300" ref="mainContent">
                    <div
                      v-for="item in content"
                      :key="item.heading"
                      class="grid grid-cols-[auto_1fr] gap-x-2 gap-y-4 p-8"
                    >
                      <div class="col-span-2 grid grid-cols-[subgrid] gap-y-1">
                        <SparkIcon
                          :icon="item.icon"
                          class="h-6 w-6"
                          :class="[iconColor[item.icon]]"
                        />
                        <div class="text-xl font-medium">
                          {{ item.heading }}
                        </div>
                        <div class="col-start-2 leading-tight">
                          {{ item.description }}
                        </div>
                      </div>
                      <div class="col-span-2 grid grid-cols-[subgrid]">
                        <div
                          class="col-start-2 overflow-clip rounded border border-gray-300 shadow-lg"
                        >
                          <ImageLoader
                            :src="getImageUrl(item.screenshot)"
                            @loaded="onImageLoaded"
                          />
                        </div>
                      </div>
                      <div class="col-span-2 grid grid-cols-[subgrid] gap-y-2">
                        <template
                          v-for="point in item.bulletPoints"
                          :key="point"
                        >
                          <SparkIcon icon="check" class="h-5 w-5" />
                          <div class="leading-tight">
                            {{ point }}
                          </div>
                        </template>
                      </div>
                      <div class="col-span-2">
                        <div class="flex justify-center">
                          <SparkTrackerButton
                            size="lg"
                            :to="item.link"
                            is-external-link
                            @click="acknowledge(false)"
                            >{{ item.linkText }}</SparkTrackerButton
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="pointer-events-none relative">
                    <div
                      class="absolute bottom-0 h-8 w-full"
                      ref="bottomMostElement"
                    ></div>
                  </div>
                </div>
              </div>

              <DialogClose
                class="absolute right-6 top-6 flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-white shadow-lg transition-colors hover:bg-gray-200 focus:outline-none active:bg-gray-300"
                @click="acknowledge(true)"
              >
                <SparkIcon icon="close" class="h-6 w-6" />
              </DialogClose>

              <Transition name="fade">
                <div
                  class="absolute bottom-6 right-8"
                  v-if="showScrollIndicator"
                >
                  <ScrollMoreIndicator @scroll="onScrollAtIndicator" />
                </div>
              </Transition>
            </div>
          </DialogContent>
        </div>
      </Transition>
    </DialogPortal>
  </DialogRoot>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grow-enter-active,
.grow-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.grow-enter-from,
.grow-leave-to {
  transform: scale(0.9);
  opacity: 0;
}
</style>
