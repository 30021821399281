<script setup lang="ts">
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

import { staticDataService } from '@/core/services';
import { analyticsTrack } from '@/core/utils/usageAnalytics';
import SparkPopup from '@/shared/components/SparkPopup.vue';
import type { StaticResourceEnum } from '@/shared/configs/staticResources';
import { AnalyticsEvent } from '@/types';
import type { SparkButtonProps } from '#/components/generic/SparkButton/types';

interface Props {
  title?: string;
  type?: StaticResourceEnum;
  color?: SparkButtonProps['color'];
  renderAs?: 'icon' | 'text';
}
const props = withDefaults(defineProps<Props>(), {
  title: 'Methodology',
  type: undefined,
  color: 'green',
  renderAs: 'icon',
});

const emit = defineEmits<{
  'popup:opened': [];
}>();

const markdown = ref<string>('');
async function loadContent() {
  try {
    if (!props.type) {
      return;
    }
    const response = await staticDataService.getResource(props.type);
    markdown.value = response.data;
  } catch (err) {
    console.error(err);
  }
}
function popupOpened() {
  loadContent();
  analyticsTrack(AnalyticsEvent.MethodologyOpened, {
    title: props.title,
  });
  emit('popup:opened');
}
</script>

<template>
  <SparkPopup :popup-title="title" @popup:opened="popupOpened()">
    <template #clickable="{ setModalVisible }">
      <slot name="clickable" :set-modal-visible="setModalVisible">
        <a-tooltip :title="renderAs === 'icon' ? title : undefined">
          <SparkTrackerButton
            :color="color"
            :shape="renderAs === 'icon' ? 'circle' : undefined"
            :class="renderAs === 'icon' ? '' : '!p-0'"
            look="text"
            size="xs"
            @click="setModalVisible(true)"
          >
            <template #icon>
              <InfoCircleOutlined />
            </template>
            <template v-if="renderAs === 'text'" #default>
              Methodology
            </template>
          </SparkTrackerButton>
        </a-tooltip>
      </slot>
    </template>
    <slot>
      <div class="prose prose-sm prose-green max-w-none">
        <a-skeleton :loading="!markdown" :paragraph="{ rows: 12 }">
          <Markdown :source="markdown"></Markdown>
        </a-skeleton>
      </div>
    </slot>
  </SparkPopup>
</template>
