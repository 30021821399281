import { getFormattedPrice as formatPrice } from '@/core/misc/PriceUtilsV2';
import type { UnitEnum } from '@/types';

export const getFormattedPrice = (
  val?: string | number | null,
  {
    unit,
    withUnit,
    placeholder = '-',
  }: {
    unit?: UnitEnum | 'usdPerMMBtu';
    withUnit?: boolean;
    placeholder?: string;
  } = {},
): string => {
  return formatPrice(val, {
    unit,
    withUnit,
    placeholder,
    product: 'gas-leba-platform',
  });
};
