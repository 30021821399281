import type { RouteRecordRaw } from 'vue-router';

import RouteView from '@/shared/components/RouteView.vue';
import AppLayout from '@/shared/layout/AppLayout.vue';
import { features } from '@/shared/misc/constants';

import SettlementTrackerPage from '../pages/settlement-tracker';

const THEDiscoverPage = () =>
  import('@/products/gas/pages/discover/THEDiscoverPage.vue');

const TTFDiscoverPage = () =>
  import('@/products/gas/pages/discover/TTFDiscoverPage.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/gas',
    component: RouteView,
    redirect: '/gas/leba/ttf',
    children: [
      {
        path: 'leba',
        component: RouteView,
        redirect: '/gas/leba/ttf',
        children: [
          {
            path: 'ttf',
            name: features.GAS_LEBA_TTF,
            component: TTFDiscoverPage,
            meta: {
              title: 'LEBA TTF',
              product: 'gas-leba-platform',
              requiresAuth: true,
              basic: false,
              layout: AppLayout,
              featureIdTemplate: `${features.FREIGHT_PHYSICAL_PREFIX}TTF`,
            },
          },
          {
            path: 'the',
            name: features.GAS_LEBA_THE,
            component: THEDiscoverPage,
            meta: {
              title: 'LEBA THE',
              product: 'gas-leba-platform',
              requiresAuth: true,
              basic: false,
              layout: AppLayout,
              featureIdTemplate: `${features.FREIGHT_PHYSICAL_PREFIX}THE`,
            },
          },
        ],
      },
      {
        path: 'indexation',
        name: features.GAS_SETTLEMENT_TRACKER,
        component: SettlementTrackerPage,
        meta: {
          title: 'Settlemtn Tracker',
          product: 'gas-leba-platform',
          requiresAuth: true,
          basic: false,
          layout: AppLayout,
        },
      },
    ],
  },
];

export default routes;
