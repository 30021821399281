<script setup lang="ts">
const emit = defineEmits<{
  scroll: [delta: number];
}>();

function onWheel(event: WheelEvent) {
  emit('scroll', event.deltaY);
}
</script>

<template>
  <a-tooltip>
    <template #title>Scroll</template>
    <div
      class="glow flex flex-col gap-2 rounded-full bg-white/50 p-2"
      @wheel="onWheel"
    >
      <SparkIcon icon="mouseScroll" class="h-8 w-8 self-center text-gray-500" />
      <SparkIcon
        icon="chevronDoubleDown"
        class="downwards-motion h-4 w-4 self-center text-gray-500"
      />
    </div>
  </a-tooltip>
</template>
<style scoped>
.glow {
  box-shadow: 0 0 10px 4px #c6cdd57c;
}

@keyframes downwards-motion {
  0% {
    transform: translateY(-4px);
    opacity: 0;
  }
  100% {
    transform: translateY(4px);
    opacity: 1;
  }
}

.downwards-motion {
  animation: downwards-motion 1s ease-out infinite;
}
</style>
