<script
  setup
  lang="ts"
  generic="T extends string | number = string, TMeta = unknown"
>
import { useVModel } from '@vueuse/core';

import type { SelectableItem, SparkProductColor } from '#/types/core';

import SparkButtonGroup from './SparkButtonGroup.vue';
import { SparkRadioGroup } from './SparkRadioGroup';

const props = withDefaults(
  defineProps<{
    title: string;
    loading?: boolean;
    options: SelectableItem<T, TMeta>[];
    modelValue?: T;
    color?: SparkProductColor;
    layout?: 'vertical' | 'horizontal';
  }>(),
  {
    modelValue: undefined,
    loading: false,
    color: 'green',
    layout: 'vertical',
  },
);

defineEmits<{
  'update:modelValue': [value: T];
  optionClicked: [value: T];
}>();

const _value = useVModel(props, 'modelValue');
</script>

<template>
  <SparkButtonGroup :title="title" :layout="layout">
    <a-skeleton
      :loading="loading"
      :title="{ width: 160 }"
      :paragraph="false"
      active
    >
      <SparkRadioGroup
        v-model="_value"
        :options="options"
        :color="color"
        @option-clicked="$emit('optionClicked', $event)"
      />
    </a-skeleton>
  </SparkButtonGroup>
</template>
