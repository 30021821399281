import type { components } from '#/types/platformapi-generated';

export type UnitMetaKey =
  | components['schemas']['AssessmentUnitEnum']
  | 'eur'
  | 'usd'
  | 'usdPerDay'
  | 'usdPerMMBtu'
  | 'eurPerMwh'
  | 'pence-per-therm';
type UnitMetaValue = {
  prefix: string;
  suffix: string;
  separator: string;
  desc: string;
};

export const UnitMeta: Record<UnitMetaKey, UnitMetaValue> = {
  'usd-per-day': {
    prefix: '$',
    suffix: 'day',
    separator: '/',
    desc: '$/day',
  },
  usdPerDay: {
    prefix: '$',
    suffix: 'day',
    separator: '/',
    desc: '$/day',
  },
  'usd-per-mmbtu': {
    prefix: '$',
    suffix: 'MMBtu',
    separator: '/',
    desc: '$/MMBtu',
  },
  usdPerMMBtu: {
    prefix: '$',
    suffix: 'MMBtu',
    separator: '/',
    desc: '$/MMBtu',
  },
  usd: {
    prefix: '$',
    suffix: '',
    separator: '',
    desc: '$',
  },
  eur: {
    prefix: '€',
    suffix: '',
    separator: '',
    desc: '€',
  },
  'eur-per-mwh': {
    prefix: '€',
    suffix: 'MWh',
    separator: '/',
    desc: '€/MWh',
  },
  eurPerMwh: {
    prefix: '€',
    suffix: 'MWh',
    separator: '/',
    desc: '€/MWh',
  },
  'pence-per-therm': {
    prefix: 'p',
    suffix: 'thm',
    separator: '/',
    desc: 'p/thm',
  },
  'no-unit': {
    prefix: '',
    suffix: '',
    separator: '',
    desc: '',
  },
} as const;
