<script
  setup
  lang="ts"
  generic="
    T extends string | number | null | undefined = string,
    TMeta = unknown
  "
>
import { DownOutlined, SearchOutlined } from '@ant-design/icons-vue';
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxInput,
  ComboboxPortal,
  ComboboxRoot,
  ComboboxTrigger,
} from 'radix-vue';
import {
  computed,
  onActivated,
  onDeactivated,
  ref,
  watch,
  watchEffect,
} from 'vue';

import type { SelectableItem, SparkProductColor } from '#/types/core';

import SparkComboBoxItem from './SparkComboBoxItem.vue';

const props = withDefaults(
  defineProps<{
    modelValue?: T;
    options?: SelectableItem<T, TMeta>[];
    color?: SparkProductColor;
    size?: 'sm' | 'md' | 'lg';
    dropdownWidth?: string;
    placeholderText?: string;
    disabled?: boolean;
    showSearch?: boolean;
    open?: boolean;
  }>(),
  {
    modelValue: undefined,
    options: () => [],
    color: 'green',
    size: 'sm',
    dropdownWidth: 'var(--radix-combobox-trigger-width)',
    placeholderText: 'Select...',
    disabled: false,
    showSearch: false,
    open: false,
  },
);

const emit = defineEmits<{
  'update:modelValue': [T];
  'update:open': [boolean];
}>();

const _open = ref(props.open);
const searchTerm = ref('');
const selectedItem = ref<SelectableItem<T, TMeta>>();
const inputEl = ref<HTMLInputElement>();
const forceMount = ref(true);

const PRODUCT_COLORS: Record<
  SparkProductColor,
  {
    hoverBorderColor?: string;
    glowColor?: string;
  }
> = {
  green: {
    hoverBorderColor: 'var(--tw-green-500)',
    glowColor: '0px 0px 1px var(--tw-green-500)',
  },
  basis: {
    hoverBorderColor: 'var(--tw-basis-500)',
    glowColor: '0px 0px 1px var(--tw-basis-500)',
  },
  access: {
    hoverBorderColor: 'var(--tw-access-500)',
    glowColor: '0px 0px 1px var(--tw-access-500)',
  },
  gas: {
    hoverBorderColor: 'var(--tw-gas-500)',
    glowColor: '0px 0px 1px var(--tw-gas-500)',
  },
};

const productColor = computed(() => {
  return PRODUCT_COLORS[props.color];
});

const filteredOptions = computed(() => {
  return searchTerm.value === ''
    ? props.options
    : props.options.filter((option) => {
        return option.name
          .toLowerCase()
          .includes(searchTerm.value.toLowerCase());
      });
});

function onSelectedItemChanged(item: SelectableItem<T, TMeta>) {
  emit('update:modelValue', item.value);
}

function loseFocus() {
  inputEl.value?.blur();
}

function onInputFocused() {
  if (!_open.value) {
    _open.value = true;
  }
}

function openDropdown() {
  if (props.disabled) {
    return;
  }
  if (!props.showSearch && !_open.value) {
    _open.value = true;
  }
}

onActivated(() => {
  forceMount.value = true;
});

onDeactivated(() => {
  forceMount.value = false;
});

watch(
  [() => props.modelValue, () => props.options],
  () => {
    selectedItem.value = props.options.find(
      (option) => option.value === props.modelValue,
    );
  },
  { immediate: true },
);

watchEffect(() => emit('update:open', _open.value));
</script>

<template>
  <ComboboxRoot
    v-model:open="_open"
    v-model:searchTerm="searchTerm"
    :model-value="selectedItem"
    :disabled="disabled"
    @update:model-value="onSelectedItemChanged"
  >
    <slot
      name="anchor"
      :selected-item="selectedItem"
      :open-dropdown="openDropdown"
    >
      <ComboboxAnchor
        :data-size="size"
        class="popover-trigger inline-flex w-full cursor-pointer select-none items-center justify-between gap-1 rounded-md border border-[#d9d9d9] bg-white font-medium text-[#000000D9] transition-colors hover:border-[--combo-box-anchor-hover-border-color] focus:border-[--combo-box-anchor-hover-border-color] focus:drop-shadow-[--combo-box-anchor-glow-color] data-[size=lg]:h-10 data-[size=md]:h-8 data-[size=sm]:h-6 data-[disabled=true]:cursor-not-allowed data-[state=open]:border-[--combo-box-anchor-hover-border-color] data-[disabled=true]:bg-gray-200 data-[size=lg]:px-5 data-[size=md]:px-4 data-[size=sm]:px-2 data-[size=lg]:text-lg data-[size=md]:text-sm data-[size=xs]:text-sm data-[disabled=true]:text-gray-400 data-[state=open]:drop-shadow-[--combo-box-anchor-glow-color] data-[disabled=true]:hover:border-[#d9d9d9]"
        :style="{
          '--combo-box-anchor-hover-border-color':
            productColor.hoverBorderColor,
          '--combo-box-anchor-glow-color': productColor.glowColor,
        }"
        :data-state="_open ? 'open' : 'closed'"
        :data-disabled="disabled"
        @click="openDropdown"
      >
        <div class="grow">
          <slot name="selected-item" :selected-item="selectedItem">
            <ComboboxInput
              :placeholder="selectedItem ? selectedItem.name : placeholderText"
              class="w-full bg-transparent focus:outline-none focus:placeholder:text-gray-500 focus-visible:outline-none"
              :class="{
                'placeholder:text-[#000000D9]': selectedItem && !disabled,
                'placeholder:text-gray-500': !selectedItem,
                'pointer-events-none': !showSearch,
              }"
              :disabled="!showSearch || disabled"
              as-child
            >
              <input
                ref="inputEl"
                type="text"
                @focus="onInputFocused"
                @keydown.enter="loseFocus"
              />
            </ComboboxInput>
          </slot>
        </div>
        <ComboboxTrigger class="combo-box-trigger" :data-size="size">
          <SearchOutlined
            v-if="showSearch && _open"
            class="combo-box-trigger-icon text-gray-500"
          />
          <DownOutlined v-else class="combo-box-trigger-icon" />
        </ComboboxTrigger>
      </ComboboxAnchor>
    </slot>

    <ComboboxPortal>
      <ComboboxContent
        :side-offset="6"
        align="start"
        position="popper"
        :force-mount="forceMount"
      >
        <Transition
          class="origin-[--radix-combobox-content-transform-origin] transition-[transform,opacity]"
          enter-from-class="scale-y-75 opacity-0"
          enter-active-class="duration-100"
          enter-to-class="scale-y-100 opacity-100"
          leave-active-class="duration-300"
          leave-to-class="scale-y-75 opacity-0"
        >
          <div
            v-if="_open"
            class="max-h-[250px] overflow-auto rounded border border-gray-300 bg-white shadow-lg"
            :style="{ minWidth: dropdownWidth }"
          >
            <slot
              v-for="option in filteredOptions"
              name="item"
              :option="option"
              :is-selected="option.value === modelValue"
            >
              <SparkComboBoxItem :key="option.name" :value="option">
                {{ option.name }}
              </SparkComboBoxItem>
            </slot>

            <div v-if="searchTerm && filteredOptions.length === 0">
              <div class="py-2 text-center text-gray-500">No results found</div>
            </div>
          </div>
        </Transition>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>

<style scoped>
.combo-box-trigger[data-size='sm'] .combo-box-trigger-icon {
  @apply text-[12px] leading-none;
}
.combo-box-trigger[data-size='md'] .combo-box-trigger-icon {
  @apply text-[13px] leading-none;
}
.combo-box-trigger[data-size='lg'] .combo-box-trigger-icon {
  @apply text-[14px] leading-none;
}
</style>
