import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

export default class PriceReleaseService extends ApiBase {
  async getPriceAssessments(
    priceAssessmentType: components['schemas']['PAPIGasPriceAssessmentEnum'],
  ) {
    const response = await this.dedupeGet<
      components['schemas']['DetailResponse_PAPIGasPriceAssessmentDTO_']
    >(`v1.0/gas/price-assessments/${priceAssessmentType}`);

    return response?.data.data;
  }

  async getPriceRelease(
    priceAssessmentType: components['schemas']['PAPIGasPriceAssessmentEnum'],
    releaseDate?: string,
  ) {
    if (releaseDate) {
      const response = await this.dedupeGet<
        components['schemas']['DetailResponse_PAPIGasPriceReleaseDTO_']
      >(`v1.0/gas/price-releases/${priceAssessmentType}/${releaseDate}`);

      return response?.data.data;
    } else {
      // no release date provided, get the latest release
      const response = await this.dedupeGet<
        components['schemas']['DetailResponse_PAPIGasPriceReleaseDTO_']
      >(`v1.0/gas/price-releases/${priceAssessmentType}/latest`);
      return response?.data.data;
    }
  }

  downloadPriceReleaseInExcelFormat(
    priceAssessmentType: components['schemas']['PAPIGasPriceAssessmentEnum'],
    releaseId: string,
  ) {
    const [vertical, assessmentType] = priceAssessmentType.split('::');
    return this.$http
      .get(
        `v1.0/gas/price-releases/${releaseId}/?vertical=${vertical}&assessmentType=${assessmentType}&format=excel`,
        {
          responseType: 'arraybuffer',
        },
      )
      .then((response: any) => {
        return {
          success: true,
          data: response?.data,
        };
      })
      .catch((err: any) => {
        return { success: false, error: err };
      });
  }
}
