<script setup lang="ts">
import { isNil } from 'lodash';
import type { Config, Datum, Layout } from 'plotly.js';
import { onBeforeUnmount, onMounted, ref, toRefs } from 'vue';

import type { SparkPlotData } from '@/types';

import { usePlotly } from '../composables/usePlotly';

const props = withDefaults(
  defineProps<{
    autoResize?: boolean;
    watchShallow?: boolean;
    options: Partial<Config>;
    data: Partial<SparkPlotData>[];
    layout: Partial<Layout>;
  }>(),
  {
    autoResize: true,
    watchShallow: true,
  },
);

const emit = defineEmits<{
  click: [...args: any[]];
  hover: [...args: any[]];
  unhover: [...args: any[]];
  selecting: [...args: any[]];
  selected: [...args: any[]];
  restyle: [...args: any[]];
  relayout: [...args: any[]];
  autosize: [...args: any[]];
  deselect: [...args: any[]];
  doubleclick: [...args: any[]];
  redraw: [...args: any[]];
  animated: [...args: any[]];
  afterplot: [...args: any[]];
}>();

const container = ref();

const {
  initEvents,
  relayout,
  plotlyMethods,
  hover,
  getOptions,
  newPlot,
  react,
  onEvent,
  mount,
  beforeUnmount,
} = usePlotly(container, toRefs(props));

onEvent((eventContext) => {
  emit(eventContext.event as any, ...eventContext.args);
});

function triggerHover(
  xTickValue: Datum,
  opts?: {
    hasSubplots?: boolean;
  },
) {
  if (!props.data) {
    return;
  }
  if (isNil(xTickValue)) {
    hover([]);
    return;
  }
  const hoverTargets = [];

  for (const [curveIndex, curve] of props.data.entries()) {
    // if disableHover returns true, then skip this trace
    if (curve.disableHover) {
      continue;
    }

    // 2d array
    if (curve.x?.length && Array.isArray(curve.x[0])) {
      console.debug('triggerHover 2d array not implemented.');
      return;
    }

    // remaining assumes 1d array
    const x = curve.x as Datum[];
    const xIndex = x?.indexOf(xTickValue) ?? -1;
    // if xTickValue is found in x, then add the trace to the hover
    if (xIndex > -1) {
      if (curve.multiHover) {
        curve.y?.forEach((v, index) => {
          hoverTargets.push({
            curveNumber: curveIndex,
            pointNumber: index,
          });
        });
      } else {
        hoverTargets.push({
          curveNumber: curveIndex,
          pointNumber: xIndex,
        });
      }
    }
  }

  if (hoverTargets.length) {
    try {
      if (opts?.hasSubplots) {
        hover(hoverTargets, ['xy', 'x2y2']);
      } else {
        hover(hoverTargets);
      }
    } catch {
      console.log('failed to hover on chart');
    }
  }
}

defineExpose({
  triggerHover,
  initEvents,
  relayout,
  ...plotlyMethods,
  hover,
  getOptions,
  newPlot,
  react,
});

onMounted(() => {
  mount();
});

onBeforeUnmount(() => {
  beforeUnmount();
});
</script>
<template>
  <div ref="container" class="h-[450px]"></div>
</template>
