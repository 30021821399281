<script setup lang="ts">
import type { AxiosError } from 'axios';
import { ref, watch } from 'vue';

import { useMarketingStore } from '@/core/stores/marketing';
import SparkPopup from '@/shared/components/SparkPopup.vue';

const props = defineProps<{ modelValue: boolean }>();

const DEFAULT_ERROR_MESSAGE =
  'Sorry we cannot process your request, please contact us.';
const DEFAULT_SUCCESS_MESSAGE = 'We have received your request.';

const feedbackText = ref('');
const loading = ref(false);
const submitted = ref(false);
const error = ref('');
const marketingStore = useMarketingStore();
const { useFeedback } = marketingStore;
const { sendFeedback } = useFeedback('gas-indexation-feedback');

const emit = defineEmits(['update:modelValue']);

function closeModal() {
  emit('update:modelValue', false);
}

function clearForm() {
  feedbackText.value = '';
}

watch(
  () => props.modelValue,
  (value: boolean) => {
    if (value) {
      clearForm();
    }
  },
);

async function handleSubmit() {
  try {
    loading.value = true;
    submitted.value = false;
    const response = await sendFeedback('acknowledge', {
      msg: feedbackText.value,
      action: 'acknowledge',
    }).catch((err: AxiosError<{ message: string }>) => {
      error.value =
        (err?.response?.data as { message: string })?.message ||
        DEFAULT_ERROR_MESSAGE;
    });
    if (response?.status === 204) {
      submitted.value = true;
      clearForm();
    } else {
      console.error(
        'Unexpected Error code: Expected 204 but received ',
        response?.status,
      );
    }
  } catch (err) {
    error.value = err as string;
  } finally {
    loading.value = false;
  }
}
</script>
<template>
  <SparkPopup
    :show="modelValue"
    popup-title="Indexation Feedback"
    @popup:closed="closeModal"
    @popup:cancel="closeModal"
  >
    <div v-if="!submitted">
      <a-form class="space-y-4" @submit.prevent="handleSubmit">
        <p>
          The suggested contract wording and indexation types have been
          developed in cooperation with market participants. Please let us know
          below if we can improve these:
        </p>
        <div>
          <a-textarea v-model:value="feedbackText"></a-textarea>
        </div>
        <div class="flex w-full justify-center">
          <SparkTrackerButton
            :loading="loading"
            color="gas"
            :disabled="!feedbackText?.length"
            shape="rounded"
            class="w-full"
            type="submit"
            >Submit</SparkTrackerButton
          >
        </div>
      </a-form>
    </div>
    <div v-else-if="error" class="text-red">{{ error }}</div>
    <div v-else>
      <p>{{ DEFAULT_SUCCESS_MESSAGE }}</p>
      <div class="align-center flex justify-center">
        <SparkTrackerButton color="gas" class="mt-4 px-8" @click="closeModal">
          OK
        </SparkTrackerButton>
      </div>
    </div>
  </SparkPopup>
</template>
