import type { components, UnitEnum } from '@/types';

export const sparkPercentConfig = {
  min: 75,
  max: 125,
  step: 5,
};

export const enum DateFormat {
  DD_MMM = 'DD MMM',
  DD_MMM_HHMM = 'DD MMM HH:mm',
  MMM_DD = 'MMM DD',
  MMM_YY = 'MMM, YY',
  MMMYY = 'MMMYY',
  DD_MMM_YY = 'DD MMM, YY',
  DAY_AND_DATE = 'ddd, DD MMM, YYYY',
  MMM = 'MMM',
  YYYY_MM_DD = 'YYYY-MM-DD',
  YY = 'YY',
  YYYY = 'YYYY',
  YYYY_MM = 'YYYY-MM',
  DD = 'DD',
  DAY_AND_TIME = 'DD MMM, YY HH:mm:ss',
}

export const UnitPrecision: Partial<
  Record<
    components['schemas']['ProductEnum'] | 'lng-freight-platform-routes',
    Partial<
      Record<UnitEnum | 'usdPerDay' | 'usdPerMMBtu' | 'eurPerMwh', number>
    >
  >
> = {
  'lng-freight-platform': {
    'usd-per-day': 1,
    'usd-per-mmbtu': 2,
    // to support legacy
    usdPerDay: 1,
    usdPerMMBtu: 2,
  },
  'lng-freight-platform-routes': {
    'usd-per-day': 1,
    'usd-per-mmbtu': 3,
    'eur-per-mwh': 3,
    // to support legacy
    usdPerDay: 1,
    eurPerMwh: 3,
    usdPerMMBtu: 3,
  },
  'lng-basis-platform': {
    'usd-per-day': 1,
    'usd-per-mmbtu': 3,
    'eur-per-mwh': 3,
    // to support legacy
    usdPerDay: 1,
    usdPerMMBtu: 3,
  },
  'access-platform': {
    'eur-per-mwh': 3,
    'usd-per-mmbtu': 3,
    // to support legacy
    eurPerMwh: 3,
    usdPerMMBtu: 3,
  },
  'gas-leba-platform': {
    'usd-per-day': 1,
    'usd-per-mmbtu': 3,
    'eur-per-mwh': 3,
    // to support legacy
    usdPerDay: 1,
    usdPerMMBtu: 3,
  },
} as const;

export const PremiumPriceTypes = ['spark-174', 'spark-neutral'];

export type ContractTypeTitleType = Partial<
  Record<components['schemas']['ContractTypeEnum'], string>
>;

export const ContractTypeTitle: ContractTypeTitleType = {
  spot: 'Spot',
  'front-month': 'Front Month',
  'front-month-half-mid': 'Front Month',
  'front-month-half-bid-offer': 'Front Month',
  forward: 'Forward Months',
  'ffa-monthly': 'Monthly',
  'ffa-yearly': 'Yearly',
};
