/*
 * Spark Colors
 */
export default (key: string, index?: number) => {
  const fontBlack = '#2a3f5f';
  const fontWhite = '#f4fdfa';
  const data: Record<string, string[]> = {
    spark: ['#0173CA', fontWhite],
    'spark-assessment': ['#0173CA', fontWhite],
    'spark-min': ['#b9b3fb', fontBlack],
    'spark-max': ['#b9b3fb', fontBlack],
    'spark-percent': ['#a8a1fa', fontBlack],
    'spark-prev': ['#93a0a7', fontBlack],
    'spark-you': ['#ff7f0e', fontBlack],
    'outlier-min': ['#DB2E20', fontWhite],
    'outlier-max': ['#DB2E20', fontWhite],
    'deals-avg': ['#847AF7', fontWhite],

    ttf: ['#86CAFE', fontBlack],
    peg: ['#D08291', fontBlack],
    nbp: ['#fbbf24', fontBlack],
    the: ['#f472b6', fontBlack],

    'sabine-fob-netforward': ['#86CAFE', fontBlack],
    'soya-fob-netforward': ['#D08291', fontBlack],
    'ras-laffen-fob-netforward': ['#fbbf24', fontBlack],
    'bintulu-fob-netforward': ['#0D94FD', fontWhite],
    'nws-fob-netforward': ['#f472b6', fontBlack],
    'min-fob-netforward': ['#0173CA', fontWhite],

    bid: ['#B8D8AA', fontBlack],
    offer: ['#EA9B9A', fontBlack],

    spark_prices: ['#0173CA', fontWhite], // Spark Green
    spark_prices_percent: ['#a8a1fa', fontBlack], // Spark Percent
    previous_spark_prices: ['#93a0a7', fontBlack], // gray shade
    submitter_prices: ['#ff7f0e', fontBlack], // safety orange
    submitter_benchmark_min: ['#b9b3fb', fontBlack], // Lighter Spark Green
    submitter_benchmark_max: ['#b9b3fb', fontBlack], // Lighter Spark Green,
    previous_submitter_prices: ['#EAB282', fontBlack],
    nwe_netback: ['#0273CA', fontWhite],
    nea_netback: ['#21c08e', fontBlack],

    'nwe-netback': ['#0273CA', fontWhite],
    'nea-netback': ['#21c08e', fontBlack],

    'spark-des': ['#0173CA', fontWhite],
    'ice-ttf': ['#0D94FD', fontWhite],
    'spark-mtd': ['#0173CA', fontWhite],

    'freight-price': ['#fbbf24', fontBlack],
    'freight-price-breakeven': ['#0273CA', fontWhite],
    'jkm-ttf-spread': ['#fbbf24', fontBlack],
    'jkm-ttf-spread-breakeven': ['#0273CA', fontWhite],
  };

  const additionalData: Record<string, string[]>[] = [
    {
      // data 0to255.com base color +5
      spark: ['#9991f9', fontWhite],
      'spark-min': ['#e6e3fe', fontBlack],
      'spark-max': ['#e6e3fe', fontBlack],
      'spark-percent': ['#a8a1fa', fontBlack],
      'spark-prev': ['#c2c9cd', fontBlack],
      'spark-you': ['#ff9a41', fontBlack],

      spark_prices: ['#9991f9', fontWhite],
      spark_prices_percent: ['#a8a1fa', fontBlack],
      previous_spark_prices: ['#c2c9cd', fontBlack],
      submitter_prices: ['#ff9a41', fontBlack],
      submitter_benchmark_min: ['#e6e3fe', fontBlack], // +3
      submitter_benchmark_max: ['#e6e3fe', fontBlack],
    },
    {
      // data 0to255.com base color +3
      spark: ['#7c71f7', fontWhite],
      'spark-min': ['#d7d3fd', fontBlack],
      'spark-max': ['#d7d3fd', fontBlack],
      'spark-percent': ['#a8a1fa', fontBlack],
      'spark-prev': ['#afb9be', fontBlack],
      'spark-you': ['#ff9a41', fontBlack],

      spark_prices: ['#7c71f7', fontWhite],
      spark_prices_percent: ['#a8a1fa', fontBlack],
      previous_spark_prices: ['#afb9be', fontBlack],
      submitter_prices: ['#ff9a41', fontBlack],
      submitter_benchmark_min: ['#d7d3fd', fontBlack], // +2
      submitter_benchmark_max: ['#d7d3fd', fontBlack],
    },
  ];
  const defaultColor = ['#b2b2b2', fontBlack];

  return (
    (typeof index === 'undefined' ? data[key] : additionalData[index][key]) ||
    defaultColor
  );
};
